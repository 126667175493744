import Swiper from "swiper/bundle";

jQuery(function ($) {
  // ------------------------------------------- SWIPER
  // ------------------------------------------- SLIDE
  $(window).load(function () {
    function swiperActivite($activite) {
      const swiper = new Swiper(".swiper__plomberie", {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        // loopAdditionalSlides: 20,
        // freeMode: true,
        // spaceBetween: 5,
        speed: 900,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      });

      $($activite)
        .find(".swiper-slide")
        .hover(
          function () {
            swiper.autoplay.stop();
          },
          function () {
            swiper.autoplay.start();
          }
        );
    }

    swiperActivite(".swiper__plomberie");

    const swiper = new Swiper(".swiper__slide", {
      slidesPerView: 1,
      loop: true,
      // loopAdditionalSlides: 20,
      // freeMode: true,
      // spaceBetween: 5,
      speed: 900,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        // el: ".swiper-pagination",
        //   clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    $(".swiper__slide .swiper-slide").hover(
      function () {
        swiper.autoplay.stop();
      },
      function () {
        swiper.autoplay.start();
      }
    );

    const swiper2 = new Swiper(".swiper__realisations", {
      slidesPerView: "auto",
      loop: true,
      // freeMode: true,
      spaceBetween: 25,
      centeredSlides: true,
      roundLengths: true,
      loopAdditionalSlides: 30,
      speed: 700,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    $(".swiper__realisations .swiper-slide").hover(
      function () {
        swiper2.autoplay.stop();
      },
      function () {
        swiper2.autoplay.start();
      }
    );
  });
});
